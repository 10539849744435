import React, { useContext } from 'react'
import { Popup, Dropdown } from 'semantic-ui-react'
import { useIntl } from "react-intl"
import styled from "styled-components"

import { LANG_NAMES } from '../../translations'
import { AppContext } from '../../providers/AppContext'

const StyledDropdown = styled(Dropdown)`
  font-size: 18px;
  font-weight: normal;
  color: #4183C4;

  &:hover {
    color: #29557e;
  }
`

const LanguageChooser = () => {
  const intl = useIntl()
  const appContext = useContext(AppContext)

  const onLocaleChange = (
    e: React.MouseEvent<HTMLElement>
  ) => {
    appContext.setLocale?.(
      (e.currentTarget as HTMLElement).getAttribute('lang') as string
    )
  }

  const getLangName = (code) => LANG_NAMES
    .filter(
      (language) =>
        language.code === code
    )[0]?.text

  const langTextName = getLangName(appContext.locale.split('-')[0])

  return (
    <StyledDropdown pointing text={langTextName}>
      <Dropdown.Menu>
        {LANG_NAMES.map((language) => (
          <Dropdown.Item
          onClick={onLocaleChange}
          icon={appContext.locale === language.code ? "check" : ""}
          text={language.text}
          lang={language.code}
          key={language.code}
          />
        ))}
      </Dropdown.Menu>
    </StyledDropdown>
  )
}

export default LanguageChooser